<template>
    <tbody v-if="isLoading">
        <tr v-for="(row, rowIndex) in rowCount" :key="'loading-' + rowIndex">
            <th scope="row"><Skeleton height="38px" /></th>
            <td v-for="(column, columnIndex) in columnCount - 1" :key="'loading-row-' + rowIndex + '-column-' + columnIndex"><Skeleton height="38px" /></td>
        </tr>
    </tbody>
    <tbody v-else>
        <template v-if="!hasDefaultSlot">
            <tr v-for="(item, itemIndex) in data.items" :key="'item-' + itemIndex">
                <th scope="row">{{ item.id }}</th>
                <td v-for="(column, columnIndex) in columns.filter(column => column.data != 'id')" :key="'item-row-' + itemIndex + '-column-' + columnIndex" v-html="item[column.data]"></td>
            </tr>
        </template>
        <template v-else>
            <slot />
        </template>
    </tbody>
</template>

<script>
import { Skeleton } from 'vue-loading-skeleton';

export default {
    name: "DatatableBody",
    props: {
        isLoading: {
            type: Boolean,
            default: true,
        },
        rowCount: {
            type: Number,
            default: 10,
        },
        columns: {
            type: Array,
            default: () => ([]),
        },
        columnCount: {
            type: Number,
            default: 10,
        },
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        Skeleton,
    },
    computed: {
        hasDefaultSlot () {
            return !!this.$slots.default
        }
    }
}
</script>