<template>
    <div>
        <div class="mb-12" v-if="filters.length > 0">
            <div class="row">
                <div class="col-lg-3 mb-6" :class="filter.length" v-for="(filter, filterIndex) in filters" :key="'filter-' + filterIndex">
                    <label>{{ filter.label_lang_key !== undefined ? $t(`service.${filter.label_lang_key}`) : filter.label }}:</label>
                    <input
                        v-if="filter.type === 'text' || filter.type === 'number'"
                        :type="filter.type"
                        class="form-control"
                        :placeholder="filter.placeholder_lang_key !== undefined ? $t(filter.placeholder_lang_key) : filter.placeholder"
                        :name="filter.name"
                        :value="selectedFilters[filter.name]"
                        @change="onChange"
                    />
                    <select
                        v-if="filter.type === 'select'"
                        class="form-control datatable-input"
                        :name="filter.name"
                        @change="onChange"
                    >
                        <option :selected="selectedFilters[filter.name] == ''" value="">{{ $t("commons.all") }}</option>
                        <option
                            v-for="(option, optionIndex) in filter.options"
                            :key="'option-' + optionIndex"
                            :value="option.value"
                            :selected="option.value === selectedFilters[filter.name]"
                        >
                            {{ $te(`service.${option.label}`) ? $t(`service.${option.label}`) : option.label }}
                        </option>
                    </select>
                    <div class="input-group" v-if="filter.type === 'date'">
                        <input
                            type="text"
                            class="form-control"
                            readonly="readonly"
                            data-type="date"
                            :name="filter.name"
                            :value="selectedFilters[filter.name]"
                            :placeholder="filter.placeholder_lang_key !== undefined ? $t(filter.placeholder_lang_key) : filter.placeholder"
                        />
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <i class="la la-times-circle-o"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <template v-if="userType === 'genesis'">
                    <!-- distributor -->
                    <div class="col-lg-3 mb-6">
                        <label>{{ $t('service.institution.distributor') }}:</label>
                        <select
                            class="form-control datatable-input"
                            name="distributor_id"
                            @change="onChange"
                        >
                            <option :selected="selectedFilters['distributor_id'] == ''" value="">{{ $t("commons.all") }}</option>
                            <option
                                v-for="(option, optionIndex) in distributors"
                                :key="'option-' + optionIndex"
                                :value="option.value"
                                :selected="option.value === selectedFilters['distributor_id']"
                            >
                                {{ option.label }}
                            </option>
                        </select>
                    </div>
                    <!-- /distributor -->
                    <!-- merchant -->
                    <div class="col-lg-3 mb-6" v-if="selectedFilters['distributor_id']">
                        <label>{{ $t('service.institution.merchant') }}:</label>
                        <select
                            class="form-control datatable-input"
                            name="merchant_id"
                            @change="onChange"
                        >
                            <option :selected="selectedFilters['merchant_id'] == ''" value="">{{ $t("commons.all") }}</option>
                            <option
                                v-for="(option, optionIndex) in merchants"
                                :key="'option-' + optionIndex"
                                :value="option.value"
                                :selected="option.value === selectedFilters['merchant_id']"
                            >
                                {{ $te(`service.${option.label}`) ? $t(`service.${option.label}`) : option.label }}
                            </option>
                        </select>
                    </div>
                    <!-- /merchant -->
                    <!-- Branch -->
                    <div class="col-lg-3 mb-6" v-if="selectedFilters['merchant_id']">
                        <label>{{ $t('service.institution.branch') }}:</label>
                        <select
                            class="form-control datatable-input"
                            name="branch_id"
                            @change="onChange"
                        >
                            <option :selected="selectedFilters['branch_id'] == ''" value="">{{ $t("commons.all") }}</option>
                            <option
                                v-for="(option, optionIndex) in branches"
                                :key="'option-' + optionIndex"
                                :value="option.value"
                                :selected="option.value === selectedFilters['branch_id']"
                            >
                                {{ $te(`service.${option.label}`) ? $t(`service.${option.label}`) : option.label }}
                            </option>
                        </select>
                    </div>
                    <!-- /Branch -->
                </template>
                <template v-if="userType === 'distributor'">
                    <!-- merchant -->
                    <div class="col-lg-3 mb-6">
                        <label>{{ $t('service.institution.merchant') }}:</label>
                        <select
                            class="form-control datatable-input"
                            name="merchant_id"
                            @change="onChange"
                        >
                            <option :selected="selectedFilters['merchant_id'] == ''" value="">{{ $t("commons.all") }}</option>
                            <option
                                v-for="(option, optionIndex) in merchants"
                                :key="'option-' + optionIndex"
                                :value="option.value"
                                :selected="option.value === selectedFilters['merchant_id']"
                            >
                                {{ $te(`service.${option.label}`) ? $t(`service.${option.label}`) : option.label }}
                            </option>
                        </select>
                    </div>
                    <!-- /merchant -->
                    <!-- Branch -->
                    <div class="col-lg-3 mb-6" v-if="selectedFilters['merchant_id']">
                        <label>{{ $t('service.institution.branch') }}:</label>
                        <select
                            class="form-control datatable-input"
                            name="branch_id"
                            @change="onChange"
                        >
                            <option :selected="selectedFilters['branch_id'] == ''" value="">{{ $t("commons.all") }}</option>
                            <option
                                v-for="(option, optionIndex) in branches"
                                :key="'option-' + optionIndex"
                                :value="option.value"
                                :selected="option.value === selectedFilters['branch_id']"
                            >
                                {{ $te(`service.${option.label}`) ? $t(`service.${option.label}`) : option.label }}
                            </option>
                        </select>
                    </div>
                    <!-- /Branch -->
                </template>
                <template v-if="userType === 'branch'">
                    <!-- Branch -->
                    <div class="col-lg-3 mb-6">
                        <label>{{ $t('service.institution.branch') }}:</label>
                        <select
                            class="form-control datatable-input"
                            name="branch_id"
                            @change="onChange"
                        >
                            <option :selected="selectedFilters['branch_id'] == ''" value="">{{ $t("commons.all") }}</option>
                            <option
                                v-for="(option, optionIndex) in branches"
                                :key="'option-' + optionIndex"
                                :value="option.value"
                                :selected="option.value === selectedFilters['branch_id']"
                            >
                                {{ $te(`service.${option.label}`) ? $t(`service.${option.label}`) : option.label }}
                            </option>
                        </select>
                    </div>
                    <!-- /Branch -->
                </template>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <button
                        class="btn btn-primary btn-primary--icon"
                        @click="applyFilters"
                    >
                        <span>
                            <i class="la la-search"></i>
                            <span>{{ $t("datatable.filter.search") }}</span>
                        </span></button
                    >&nbsp;&nbsp;
                    <button
                        class="btn btn-secondary btn-secondary--icon"
                        @click="resetFilters"
                    >
                        <span>
                            <i class="la la-close"></i>
                            <span>{{ $t("datatable.filter.reset") }}</span>
                        </span>
                    </button>
                </div>
            </div>
        </div>

        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
            <div style="overflow-x: auto;">
                <table
                    class="table table-striped table-bordered collapsed no-footer dataTable dtr-inline"
                    style="width: 100%"
                >
                    <DatatableHead :columns="this.columns" @sort="sortData($event)">
                        <slot name="head"></slot>
                    </DatatableHead>
                    <DatatableBody :isLoading="this.isLoading" :columns="this.columns" :data="this.data">
                        <slot name="body"></slot>
                    </DatatableBody>
                </table>
            </div>
            <DatatablePagination v-if="!this.isLoading" :totalCount="this.data.total" :pageSize="this.data.perPage" :currentPage="this.data.currentPage" :lastPage="this.data.lastPage" @changePage="changePage($event)" @changePageSize="changePageSize($event)" />
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import moment from "moment";
import DatatableHead from "./DatatableHead.vue";
import DatatableBody from "./DatatableBody.vue";
import DatatablePagination from "./DatatablePagination.vue";
import ServicesOld from "@/config/_axios_old";
import API_OLD from "../../api/index_old";

export default {
    name: "Datatable",
    props: {
        url: {
            type: String,
            required: true,
        },
        dataUrl: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            isLoading: true,
            filters: [],
            selectedFilters: {},
            columns: [],
            data: {},
            page: 1,
            orderBy: '',
            orderDir: '',
            length: 10,
            distributors: [],
            merchants: [],
            branches: [],
            userType: 'branch'
        };
    },
    components: {
        DatatableHead,
        DatatableBody,
        DatatablePagination
    },
    methods: {
        async loadDatatable() {
            const self = this;
            const res = await ServicesOld.get(self.url);
            self.filters = res.data.filters;
            self.columns = res.data.columns;
            self.$nextTick(function() {
                res.data.filters?.forEach(element => {
                    self.initDateFilterType(element);
                });
            })
        },
        async filterData(selectedFilters) {
            this.$set(this, "selectedFilters", selectedFilters);
            this.$set(this, "isLoading", true);
            this.loadData();
        },
        async loadData() {
            if (!this.isLoading) {
                this.$set(this, "isLoading", true);
            }
            
            if (this.selectedFilters.reset) {
                this.page = 1;
                this.length = 10;
                this.orderBy = '';
                this.orderDir = '';
                this.$delete(this.selectedFilters, 'reset');
            }

            let params = this.selectedFilters;
            params.page = this.page;
            params.orderBy = this.orderBy;
            params.orderDir = this.orderDir;
            params.length = this.length;
            
            const res = await ServicesOld.post(this.dataUrl, params);

            this.$set(this, "data", res.data);
            this.$emit("data", res.data.items);
            this.$set(this, "isLoading", false);
        },
        async sortData(column) {
            this.$set(this, "isLoading", true);
            this.$set(this, "orderBy", column.orderBy);
            this.$set(this, "orderDir", column.orderDir);
            this.loadData();
        },
        async changePage(page) {
            this.$set(this, "isLoading", true);
            this.$set(this, "page", page);
            this.loadData();
        },
        async changePageSize(length) {
            this.$set(this, "isLoading", true);
            this.$set(this, "length", length);
            this.loadData();
        },
        applyFilters() {
            this.loadData();
        },
        resetFilters() {
            this.selectedFilters = {};
            this.$set(this.selectedFilters, 'reset', true);
            this.applyFilters();
        },
        onChange(event) {
            this.$set(this.selectedFilters, event.target.name, event.target.value);
            if(event.target.name === 'distributor_id') {
                this.merchants = [];
                this.branches = [];
                this.$set(this.selectedFilters, 'merchant_id', '');
                this.$set(this.selectedFilters, 'branch_id', '');
                this.getMerchants(event.target.value);
            }
            if(event.target.name === 'merchant_id') {
                this.branches = [];
                this.$set(this.selectedFilters, 'branch_id', '');
                this.getBranches(event.target.value);
            }
        },
        async getDistributors() {
            try {
                const res = await ServicesOld.post(API_OLD.institutionsGetDistributor, {});
                let data = [];
                if(res.data.length > 0) {
                    data = res.data.map(val => ({ ...val, label: val.name, value: val.id }));
                }
                this.distributors = data;
            } catch (error) {
                console.log('DatatableMerchant@getDistributors error: ', error.response?.data);
            }
        },
        async getMerchants(distributorId) {
            if(!distributorId) {
                return;
            }
            try {
                const res = await ServicesOld.post(API_OLD.institutionsGetDistributorMerchants, {
                    institution_id: distributorId
                });
                let data = [];
                if(res.data.length > 0) {
                    data = res.data.map(val => ({ ...val, label: val.name, value: val.id }));
                }
                this.merchants = data;
            } catch (error) {
                console.log('DatatableMerchant@getMerchants error: ', error.response?.data);
            }
        },
        async getBranches(merchantId) {
            if(!merchantId) {
                return;
            }
            try {
                const res = await ServicesOld.post(API_OLD.institutionsGetMerchantBranches, {
                    institution_id: merchantId
                });
                let data = [];
                if(res.data.length > 0) {
                    data = res.data.map(val => ({ ...val, label: val.name, value: val.id }));
                }
                this.branches = data;
            } catch (error) {
                console.log('DatatableMerchant@getBranches error: ', error.response?.data);
            }
        },
        initDateFilterType(filter) {
            if(filter.type === 'date') {
                const self = this;
                // predefined ranges
                const start = moment().subtract(29, "days");
                const end = moment();
    
                $('input[data-type="date"]').daterangepicker({
                    buttonClasses: " btn",
                    applyClass: "btn-primary",
                    cancelClass: "btn-secondary",
                    startDate: start,
                    endDate: end,
                    locale: {
                        cancelLabel: self.$t("commons.cancel"),
                        applyLabel: self.$t("commons.approve"),
                        customRangeLabel: self.$t("dt_table.custom_range"),
                    },
                    ranges: {
                        [self.$t("dt_table.today")]: [moment(), moment()],
                        [self.$t("dt_table.yesterday")]: [
                            moment().subtract(1, "days"),
                            moment().subtract(1, "days"),
                        ],
                        [self.$t("dt_table.last_x_days", { days: 7 })]: [
                            moment().subtract(6, "days"),
                            moment(),
                        ],
                        [self.$t("dt_table.last_x_days", { days: 30 })]: [
                            moment().subtract(29, "days"),
                            moment(),
                        ],
                        [self.$t("dt_table.this_month")]: [
                            moment().startOf("month"),
                            moment().endOf("month"),
                        ],
                        [self.$t("dt_table.last_month")]: [
                            moment().subtract(1, "month").startOf("month"),
                            moment().subtract(1, "month").endOf("month"),
                        ],
                    }
                }, function(start, end) {
                    $(this).val(start.format("DD/MM/YYYY") + " - " + end.format("DD/MM/YYYY"));
                    
                    self.$set(self.selectedFilters, filter.name, start.format("DD/MM/YYYY") + ' - ' + end.format("DD/MM/YYYY"));
                });
            }

        },
        
    },
    created() {
        this.loadDatatable();
        this.loadData();

        /* /me instution_type
        type = 10 ise Distributor, Bayi, Şube 	(genesis)
        type = 20 ise Bayi Şube				(distribütorse)
        type = 30 ise Şube listelencek		(bayi) */

        this.$store.dispatch("customer/GET_USER").then((res) => {
            const user = res.data;
            const userTypes = {
                10: "genesis",
                20: "distributor",
                30: "branch",
            }
            this.userType = user?.instution_type ? userTypes[user.instution_type] : 'branch';
            if(this.userType === 'genesis') {
                this.getDistributors();
            }
            if(this.userType === 'distributor') {
                this.getMerchants(user.institution_id);
            }
            if(this.userType === 'branch') {
                this.getBranches(user.institution_id);
            }
        })
    },
    watch: {
        dataUrl: function (newValue, oldValue) {
            if(newValue !== oldValue) {
                this.loadData();
            }
        }
    }
};
</script>

<style scoped>
@media only screen and (max-width: 567px) {
  td {
    width: 100%;
    display: block;
  }
}
</style>
