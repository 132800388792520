<template>
  <div
    class="modal fade show"
    v-if="modalVisible"
  >
    <div class='modal__backdrop'></div>
    <div class="modal-dialog modal-xl zindex-2 modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title" id="exampleModalLabel">
            # {{ cryptobank.order_id }} · {{ $t('merchant.details_transaction_modaltitle') }}
          </h5>
          <button type="button" class="close" @click="closeModal()">
            <i aria-hidden="true" class="ki ki-close font-size-h4"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row d-flex flex-wrap px-2">
            <div class="col-md-6 col-sm-12">
              <div class="row">
                <p class="col-md-4 col-sm-12">{{ $t('commons.dealer') }}:</p>
                <b class="col-md-8 col-sm-12">{{ modal_data.dealer }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('commons.name_surname') }}</p>
                <b class="col-md-8 col-sm-12">{{ cryptobank.fullname }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('commons.amount') }}:</p>
                <b class="col-md-8 col-sm-12">{{ modal_data.amount }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">
                  {{ $t('merchant.details_amount_customer_pay') }}:
                </p>
                <b class="col-md-8 col-sm-12">{{ modal_data.amountCostumerPay }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('merchant.details_amount_due_customer') }}:</p>
                <b class="col-md-8 col-sm-12">{{ modal_data.amountDueToCustomer }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('merchant.commission') }}:</p>
                <b class="col-md-8 col-sm-12">{{ modal_data.commission }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('merchant.commission') }} %:</p>
                <b class="col-md-8 col-sm-12">{{ modal_data["commission%"] }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('merchant.details_total_price') }}</p>
                <b class="col-md-8 col-sm-12">{{ modal_data.totalPrice }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('merchant.details_total_priceUSD') }}:</p>
                <b class="col-md-8 col-sm-12">{{ modal_data.totalUsd }}</b>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="row">
                <p class="col-md-4 col-sm-12">{{ $t('merchant.details_operation_code') }}:</p>
                <b class="col-md-8 col-sm-12">{{ cryptobank.order_id }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('finance.wallet_address') }}:</p>
                <b class="col-md-8 col-sm-12 text-break">{{ modal_data.wallet }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('merchant.txid') }}:</p>
                <b class="col-md-8 col-sm-12">{{ detailsModalData.txid }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('merchant.sender') }}:</p>
                <b class="col-md-8 col-sm-12">{{ modal_data.whoWillSend }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('merchant.details_amount_utside') }}:</p>
                <div class="col-md-8 col-sm-12">
                  <b class="col-12 row">{{ modal_data['buy/sellFromOutside'] }}</b>
                  <button v-if="canOutSidePurchase" type="button" class="btn btn-info btn-sm" @click.prevent="outsidePurchase">
                    {{ $t('merchant.details_bsbtn_outside') }}
                  </button>
                </div>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('commons.state') }}</p>
                <b class="col-md-8 col-sm-12" v-html="modal_data.state"></b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('merchant.details_modal_order_date') }}:</p>
                <b class="col-md-8 col-sm-12">{{ cryptobank.created_at }}</b>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <template v-if="isPendingOpertaionApproval && checkPermission('crm.merchant.transactions.pending.action')">
            <template v-if="canApproveOrCancel">
              <p>
                {{ $t('merchant.process_finalized_by_the_system') }}
              </p>
              <input
                class="form-control col-md-5 col-sm-12"
                type="text"
                :placeholder="$t('merchant.details_modal_input_placeholder')"
                :title="$t('merchant.details_modal_input_placeholder')"
                v-model="smsConfirmForm.smart"
              />
              <button type="button" class="btn btn-success font-weight-bold" @click.prevent="approveDeclineProcess('20')">
                {{ $t('merchant.btn_approve') }}
              </button>
              <button type="button" class="btn btn-danger font-weight-bold" @click.prevent="approveDeclineProcess('30')">
                {{ $t('merchant.btn_decline') }}
              </button>
            </template>
            <p v-else>
              {{ $t('merchant.process_managed_in_withdraw_request') }}
            </p>
          </template>
          <button
            type="button"
            class="btn btn-outline-light"
            @click="closeModal()"
            data-dismiss="modal"
          >
            {{ $t('merchant.btn_close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Modal",
  props: {},
  data() {
    return {
        loading: true,
    };
  },
  components: {},
  computed: {
    ...mapState({
      modalVisible: (state) => state.transactions.showDetailsModal.visible,
      id: (state) => state.transactions.showDetailsModal.id,
      detailsModalData: (state) => state.transactions.detailsModalData,
      cryptobank: (state) => state.transactions.detailsModalData.cryptobank,
      modal_data: (state) => state.transactions.detailsModalData.modal_data,
      permissions: (state) => state.customer.permissions,
      smsConfirmForm: (state) => state.transactions.smsConfirmForm
    }),
    canOutSidePurchase() {
      return this.permissions?.some(val => val.name == 'crm.merchant.transactions.pending.out-purchase');
    },
    isPendingOpertaionApproval: function() {
      return this.modal_data?.stateCode === 10 || false;
    },
    canApproveOrCancel() {
      // return +this.cryptobank?.type !== 20;
      return true;
    }
  },
  methods: {
    ...mapActions("transactions", ["GET_DETAIL_MODAL_DATA", "TRANSACTION_PENDING_SMS_CODE"]),
    closeModal() {
      this.$store.commit('transactions/detailsModal', { id: '', visible: false});
    },
    approveDeclineProcess(status = 'approve') {
      this.TRANSACTION_PENDING_SMS_CODE({ transaction_id: this.id, status });
    },
    outsidePurchase() {
      const { currency_id, currency_code,} = this.cryptobank;
      const cryptobankAmount = this.cryptobank?.data?.amount || '';
      localStorage.setItem('outsidePurchase', JSON.stringify({ 
        type: '10', // 10: buy, 20: sell
        typeStr: this.$t('merchant.buy'),
        fromDetailModal: true,
        buySellFromOutside: this.modal_data['buy/sellFromOutside'],
        amountFormatted: this.modal_data.amount,
        cryptobankAmount,
        currencyId: currency_id,
        currencyCode: currency_code,
      }))
      this.$router.push({ 
        name: 'outside-purchase',
        query: { id: this.id }
      });
      this.closeModal();
    },
    checkPermission( permission) {
      let status = false;
      this.permissions?.forEach(function (item) {
        if (item.name == permission) {
          status = true;
        }
      });
      return status
    },
  },
  created() { },
};
</script>
<style scoped>
  .show {
    display: block;
  }
  .modal__backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
</style>