<template>
    <div class="d-flex align-items-center justify-content-between mt-6 mb-6">
        <div class="dataTables_length">
            <label class="mb-0">
                {{ $t('datatable.pagination.pageSizeFirst') }}
                <select
                    class="custom-select custom-select-sm form-control form-control-sm"
                    v-model="length"
                    @change="changePageSize"
                >
                    <option
                        v-for="(option, optionIndex) in [10, 25, 50, 100]"
                        :key="'option-' + optionIndex"
                        :value="option"
                        :selected="option === length"
                    >
                        {{ option }}
                    </option>
                </select>
                {{ $t('datatable.pagination.pageSizeLast') }}
            </label>
        </div>
        <div class="dataTables_info pt-0">
            {{ $t('datatable.pagination.info', { totalCount: totalCount, betweenStart: ((currentPage - 1) * pageSize) + 1, betweenEnd: currentPage * pageSize}) }}
        </div>
        <div class="dataTables_paginate paging_full_numbers">
            <ul class="pagination mb-0">
                <li class="paginate_button page-item first" :class="currentPage == 1 ? 'disabled' : ''" @click="changePage(1)">
                    <a href="#" class="page-link">{{ $t('datatable.pagination.first') }}</a>
                </li>
                <li class="paginate_button page-item previous" :class="currentPage == 1 ? 'disabled' : ''" @click="changePage(currentPage - 1 > 0 ? currentPage - 1 : 1)">
                    <a href="#" class="page-link">{{ $t('datatable.pagination.prev') }}</a>
                </li>
                <li v-for="page in pages" :key="'page-' + page"  class="paginate_button page-item" :class="currentPage == page ? 'active' : ''" @click="changePage(page)">
                    <a href="#" class="page-link">{{ page }}</a>
                </li>
                <li class="paginate_button page-item next" :class="currentPage == lastPage ? 'disabled' : ''" @click="changePage(currentPage + 1 < lastPage ? currentPage + 1 : lastPage)">
                    <a href="#" class="page-link">{{ $t('datatable.pagination.next') }}</a>
                </li>
                <li class="paginate_button page-item last" :class="currentPage == lastPage ? 'disabled' : ''" @click="changePage(lastPage)">
                    <a href="#" class="page-link">{{ $t('datatable.pagination.last') }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "DatatablePagination",
    data() {
        return {
            length: 10,
        };
    },
    created() {
        this.length = this.pageSize;
    },
    props: {
        totalCount: {
            type: Number,
            default: 0
        },
        currentPage: {
            type: Number,
            default: 1
        },
        lastPage: {
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            default: 10
        }
    },
    computed: {
        pages() {
            let pages = [];
            let startPage = 1;
            let endPage = this.lastPage;

            if (this.lastPage <= 7) {
                return this.lastPage;
            } else {
                if (this.currentPage <= 3) {
                    startPage = 1;
                    endPage = 7;
                } else {
                    startPage = this.currentPage - 3;

                    if (this.currentPage + 3 <= this.lastPage) {
                        endPage = this.currentPage + 3;
                    } else {
                        endPage = this.lastPage;
                    }
                }
            }

            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }

            return pages;
        }
    },
    methods: {
        changePage(page) {
            if (page !== this.currentPage) {
                this.$emit("changePage", page);
            }
        },
        changePageSize(event) {
            this.$emit("changePageSize", event.target.value);
        }
    }
};
</script>