<template>
    <thead>        
        <template v-if="!hasDefaultSlot">
            <tr>
                <th
                    v-for="(column, columnIndex) in columns"
                    :key="'column-' + columnIndex"
                    scope="col"
                    :class="column.sortable ? 'sorting ' + (orderBy == column.data ? (orderDir == 'asc' ? 'sorting_asc text-primary' : 'sorting_desc text-primary') : '') : 'sorting_disabled'"
                    @click="sort(column)"
                >
                    {{ column.label_lang_key !== undefined ? $t(`service.${column.label_lang_key}`) : column.label }}
                </th>
            </tr>
        </template>
        <template v-else>
            <slot />
        </template>        
    </thead>
</template>

<script>
export default {
    name: "DatatableHead",
    props: {
        columns: {
            type: Array,
            default: function () {
                return [];
            },
        }
    },
    data() {
        return {
            orderBy: '',
            orderDir: 'asc'
        }
    },
    methods: {
        sort(column) {
            if (column.sortable) {
                if (this.orderBy === column.data) {
                    this.orderDir = this.orderDir === 'asc' ? 'desc' : 'asc';
                } else {
                    this.orderBy = column.data;
                    this.orderDir = 'asc';
                }

                this.$emit("sort", {
                    'orderBy': this.orderBy,
                    'orderDir': this.orderDir,
                });
            }
        },
    },
    computed: {
        hasDefaultSlot () {
            return !!this.$slots.default
        }
    }
};
</script>